<template>
  <div class="ease-in">
    <v-container>
      <v-row justify="center" class="mt-5" text="center">
        <transition
          appear
          @before-enter="welcomeBeforeEnter"
          @enter="welcomeEnter"
          @after-enter="$emit('after-enter')"
        >
          <div class="welcome">
            <p id="welcomeText">
              Welcome!!
            </p>
          </div>
        </transition>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Splitting from "splitting";
import { gsap } from "gsap";
export default {
  setup(props, { emit }) {
    const welcomeBeforeEnter = (el) => {
      const chars = Splitting({
        target: el.querySelector("#welcomeText"),
        by: "chars",
      });
      gsap.set(chars[0].chars, {
        y: "-100%",
        opacity: 0,
      });
    };

    const welcomeEnter = (el, done) => {
      const tl = gsap.timeline({ onComplete: done });

      const chars = Splitting({
        target: el.querySelector("#welcomeText"),
        by: "chars",
      });
      tl.to(chars[0].chars, {
        opacity: 1,
        duration: 1,
        ease: "bounce.out",
        stagger: 0.1,
      })
        .to(chars[0].chars, {
          opacity: 0,
          duration: 0.5,
          ease: "back.out(2)",
          stagger: 0.1,
        })
        .to(".ease-in", {
          y: "100%",
          delay: 0.5,
          duration: 1,
          onComplete: () => {
            emit("after-enter");
          },
        });
    };

    return { welcomeBeforeEnter, welcomeEnter };
  },
};
</script>
<style scoped>
.welcome {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15vw;
  text-align: center;
  color: #fff;
}

.ease-in {
  background-color: black;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: grid;
  pointer-events: none;
}
</style>
<template>
  <v-card>
    <v-list dense>
      <p class="text-h4 mx-3 my-2 first-letter-color">Page Link</p>
      <v-list-item-group v-model="selectedItem">
        <v-list-item v-for="item in menuItems" :key="item.name" :to="item.url">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import constants from "@/common/constants";

export default {
  data: () => ({
    menuItems: constants.menuItems,
    selectedItem: 1,
  }),
};
</script>

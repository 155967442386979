<template>
  <v-app id="v-app">
    <div class="ease-in" v-show="!showMainContents">
      <EaseIn @after-enter="handleEaseInTransitionEnd" />
    </div>
    <AppHeader v-if="showMainContents" />
    <v-container v-if="showMainContents" class="main-contents align-center">
      <v-main class="routerWrapper">
        <router-view />
      </v-main>
      <AppFooter />
    </v-container>
  </v-app>
</template>

<script>
import EaseIn from "@/components/Home/EaseIn.vue";
import AppHeader from "@/components/Header/AppHeader.vue";
import AppFooter from "@/components/Footer/AppFooter.vue";

export default {
  name: "App",
  components: {
    EaseIn,
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      drawer: null,
      showMainContents: false,
    };
  },
  methods: {
    handleEaseInTransitionEnd() {
      this.$nextTick(() => {
        this.showMainContents = true;
      });
    },
    
  },
};
</script>
<style scoped>
#v-app {
  background-color: #f5f5f5;
}
.main-contents {
  max-width: 100%;
  padding: 0;
}
</style>

<style>
.first-letter-color:first-letter {
  color: #304FFD;
}
</style>
<template>
  <header>
    <v-navigation-drawer app v-model="drawer" clipped>
      <SideTab />
    </v-navigation-drawer>
    <transition appear @before-enter="headerBeforeEnter" @enter="headerEnter">
      <v-app-bar class="gradient" app clipped-left>
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="d-flex d-sm-none"
        ></v-app-bar-nav-icon>
        <HeadNav />
      </v-app-bar>
    </transition>
  </header>
</template>

<script>
import SideTab from "@/components/Header/SideTab.vue";
import HeadNav from "@/components/Header/HeadNav.vue";
import gsap from "gsap";
export default {
  name: "AppHeader",
  components: {
    SideTab,
    HeadNav,
  },
  data() {
    return {
      drawer: false,
    };
  },

  setup() {
    // ヘッダーが上から下がってくるアニメーション
    const headerBeforeEnter = (el) => {
      gsap.set(el, {
        opacity: 0,
      });
    };

    const headerEnter = (el, done) => {
      gsap.to(el, {
        opacity: 1,
        duration: 2,
        y: "0",
        ease: "power1.out",
        onComplete: done,
      });
    };
    return { headerBeforeEnter, headerEnter };
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to right, #f5f5f5, 70%, #a9a9a9) !important;
}
</style>
<template>
  <v-tabs class="d-none d-sm-flex text-indigo-accent-4">
    <v-tab v-for="item in menuItems" :key="item.name" :to="item.url">{{
      item.name
    }}</v-tab>
  </v-tabs>
</template>
<script>
import constants from "@/common/constants";
export default {
  data: () => ({
    menuItems: constants.menuItems,
  }),
};
</script>


export default {
    menuItems: [
        {
            name: 'HOME',
            url: '/',
            icon: 'mdi-home'
        },
        {
            name: 'ABOUT',
            url: '/about',
            icon: 'mdi-account'
        },
        {
            name: 'SKILLS',
            url: '/skills',
            icon: 'mdi-lightbulb'
        },
        {
            name: 'WORKS',
            url: '/works',
            icon: 'mdi-book-open-variant'
        },
        {
            name: 'CONTACT',
            url: '/contact',
            icon: 'mdi-email'
        }
    ]
}

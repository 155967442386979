import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueGtag from 'vue-gtag'
import { loadFonts } from "./plugins/webfontloader";

loadFonts();

createApp(App)
  .use(router)
  .use(VueGtag, {
      config: {
        id: "G-2NSL1JHX8Z",
      },
    },
    router
  )
  .use(vuetify)
  .mount("#app");

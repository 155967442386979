import { createRouter, createWebHistory } from 'vue-router';
import gsap from 'gsap';

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')

  },
  {
    path: '/skills',
    name: 'Skills',
    component: () => import('@/views/Skills.vue')
  },
  {
    path: '/works',
    name: 'Works',
    component: () => import('@/views/Works.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/*',
    name: '',
    component: () => import('@/views/Home.vue')
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0
    }
  },
});

router.beforeEach((to, from, next) => {
  const tl = gsap.timeline();
  tl.to(".main-contents", {
    opacity: 0,
    duration: 0.25,
    onComplete: () => {
      next();
    }
  }).to(".main-contents", {
    duration: 0.25,
    scale: 1,
    y: "0",
    opacity: 1,

  }, 1);
});


router.afterEach((to)=>{
  let title = "TS's Portfolio-"
  title += to.name;

  document.title = title;
  

})

export default router;
